import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Greeting from "../components/greeting";
import { submitEntry } from "../services/submitEntry";
import { v4 as uuidv4 } from "uuid";
import Agreements from "../components/agreements";
import TournamentSelectionOptions from "../components/tournament-selection-options";
import TournamentSelectionNotice from "../components/tournament-selection-notice";
import FunctionGuestSelectionOptions from "../components/function-guest-selection-options";
import EntryIntro from "../components/entry-intro";
import EventStateTitlesSelectionOptions from "../components/event-StateTitles-selection-options";
import EventGympieSelectionOptions from "../components/event-Gympie-selection-options";

export default function Home() {
  const [entryClosedTournaments, setEntryClosedTournaments] = useState(["StateTitles_Labrador_2024_0503", "AlexHills_2023_1125"]);

  const [entryId, setEntryId] = useState(uuidv4());

  const [entry, setEntry] = useState({
    tournamentName: "", playerName: "", playerPhone: "", playerEmail: "", playerClubName: "", entryNotes: ""
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const [eventDivision, setEventDivision] = useState("NA");
  const [playerAddress, setPlayerAddress] = useState("");
  const [playerDob, setPlayerDob] = useState("");

  const [canPlayFri, setCanPlayFri] = useState(true);

  const [isGoingSatDinner, setIsGoingSatDinner] = useState(false);
  const [satDinnerGuestCount, setSatDinnerGuestCount] = useState("0");

  const [isPaidMember, setIsPaidMember] = useState(false);
  const [isFreeTournament, setIsFreeTournament] = useState(false);

  const [isEntryClosed, setIsEntryClosed] = useState(false);

  useEffect(() => {
    setIsEntryClosed(!!entryClosedTournaments.includes(entry.tournamentName));
  }, [entry.tournamentName, entryClosedTournaments]);

  useEffect(() => {
    setEntry({
      ...entry,
      entryNotes: (eventDivision + ". ") + (canPlayFri ? ". " : "Play Fri No. ") + (isGoingSatDinner ? "Sat dinner Yes, " + satDinnerGuestCount
        + " guest(s). " : ". ") + (playerAddress + ". ") + (playerDob + ". ") + (isPaidMember ? "Mem Yes, " + (isFreeTournament ? "Free Yes. " : "Free No. ") : ". ")
    });
  }, [eventDivision, canPlayFri, isGoingSatDinner, satDinnerGuestCount, playerAddress, playerDob, isPaidMember, isFreeTournament]);

  const handleSubmitEntry = e => {
    e.preventDefault();
    setIsInProgress(true);
    submitEntry(entryId, entry.tournamentName, entry.playerName, entry.playerPhone, entry.playerEmail, entry.playerClubName, entry.entryNotes).then(() => {
      setIsSubmitted(true);
      setIsInProgress(false);
    });
  };

  return (<Layout>
    <Greeting playerName={entry.playerName} />

    {/* -- BEFORE form submit -- */}
    {!isSubmitted && (<form onSubmit={handleSubmitEntry}>
      <EntryIntro section="EntryIntro" />

      <div>
        <label>
          Tournament
          <br />
          <select
            required
            style={{ width: "100%", height: "35px" }}
            value={entry.tournamentName}
            onChange={event => setEntry({ ...entry, tournamentName: event.target.value })}
            onBlur={event => setEntry({ ...entry, tournamentName: event.target.value })}
          >
            <TournamentSelectionOptions />
          </select>
        </label>

        <TournamentSelectionNotice
          tournamentName={entry.tournamentName}
          isEntryClosed={isEntryClosed}
        />
      </div>
      <br />

      <div>
        <label>
          Player Full Name
          <br />
          <input
            required
            minLength="3"
            type="text"
            style={{ width: "100%" }}
            placeholder="Jane Doe"
            value={entry.playerName}
            onChange={event => setEntry({ ...entry, playerName: event.target.value })}
          />
        </label>
        <em style={{ fontSize: "75%" }}>
          Only one player per entry. To add another player, start a new
          entry after you submit this entry
        </em>
      </div>
      <br />

      <div>
        <label>
          Player Phone
          <br />
          <input
            required
            type="tel"
            style={{ width: "100%" }}
            pattern="^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$"
            placeholder="0412345678"
            value={entry.playerPhone}
            onChange={event => setEntry({ ...entry, playerPhone: event.target.value })}
          />
        </label>
      </div>
      <br />
      <div>
        <label>
          Player Email
          <br />
          <input
            required
            type="email"
            style={{ width: "100%" }}
            placeholder="jane@gmail.com"
            value={entry.playerEmail}
            onChange={event => setEntry({ ...entry, playerEmail: event.target.value })}
          />
        </label>
      </div>
      <br />

      {entry.tournamentName === "QMSA_AnnualMembership_2025" && (<>
        <div>
          <label>
            Player Address
            <br />
            <input
              required
              name="notASearchField"
              minLength="12"
              type="text"
              style={{ width: "100%" }}
              placeholder="For example, 18 Allamanda Dr, Daisy Hill, QLD 4127"
              value={playerAddress}
              onChange={event => setPlayerAddress(event.target.value)}
            />
          </label>
          <em style={{ fontSize: "75%" }}>
            Residential or postal
          </em>
        </div>
        <br />
      </>)}

      <div>
        <label>
          Player Club
          <br />
          <input
            required
            type="text"
            style={{ width: "100%" }}
            placeholder="For example, Daisy Hill Squash Club"
            value={entry.playerClubName}
            onChange={event => setEntry({ ...entry, playerClubName: event.target.value })}
          />
        </label>
      </div>
      <br />

      {entry.tournamentName !== "QMSA_AnnualMembership_2025" && (<>
        <div>
          <label>
            <input
              name="isPaidMember"
              style={{
                marginRight: "10px", height: "25px", width: "25px"
              }}
              type="checkbox"
              checked={isPaidMember}
              onChange={() => setIsPaidMember(!isPaidMember)}
            />
            Have registered and paid annual membership
          </label>
          <br />
          {isPaidMember && (<>
            <label>
              <input
                name="isFreeTournament"
                style={{
                  marginRight: "10px", height: "25px", width: "25px"
                }}
                type="checkbox"
                checked={isFreeTournament}
                onChange={() => setIsFreeTournament(!isFreeTournament)}
              />
              Claim this as one free tournament in 2025
            </label>
          </>)}
        </div>
        <br />
      </>)}


      {entry.tournamentName === "Ipswich_2025_0411" && (<>
        <div>
          <label>
            Event
            <br />
            <select
              required
              style={{ width: "100%", height: "35px" }}
              value={eventDivision}
              onChange={event => setEventDivision(event.target.value)}
              onBlur={event => setEventDivision(event.target.value)}
            >
              <EventGympieSelectionOptions />
            </select>
          </label>
        </div>
        <br />
      </>)}

      {entry.tournamentName === "HerveyBay_2025_0125" && (<>
        <div>
          <label>
            <input
              name="isGoingSatDinner"
              style={{
                marginRight: "10px", height: "25px", width: "25px"
              }}
              type="checkbox"
              checked={isGoingSatDinner}
              onChange={() => setIsGoingSatDinner(!isGoingSatDinner)}
            />
            Will attend Saturday Dinner
          </label>
          <br />
          {isGoingSatDinner && (<>
            <label>
              <select
                required
                style={{ width: "100%", height: "35px" }}
                value={satDinnerGuestCount}
                onChange={event => setSatDinnerGuestCount(event.target.value)}
                onBlur={event => setSatDinnerGuestCount(event.target.value)}
              >
                <FunctionGuestSelectionOptions />
              </select>
            </label>
          </>)}
        </div>
        <br />
      </>)}

      {entry.tournamentName === "StateTitles_AcaciaRidge_2025_0502" && (<>
        <div>
          <label>Player Date of Birth
            <br />
            <input required name="playerDob" type="date" value={playerDob} min="1920-01-01" max="1995-12-31"
                   onChange={event => setPlayerDob(event.target.value)} />
          </label>
          <em style={{ fontSize: "75%" }}>
            &nbsp; must be between 1920 and 1995
          </em>
        </div>
        <br />

        <div>
          <label>
            Event
            <br />
            <select
              required
              style={{ width: "100%", height: "35px" }}
              value={eventDivision}
              onChange={event => setEventDivision(event.target.value)}
              onBlur={event => setEventDivision(event.target.value)}
            >
              <EventStateTitlesSelectionOptions />
            </select>
          </label>
          <em style={{ fontSize: "75%" }}>
            Use age as at 2nd May, 2025
          </em>
        </div>
        <br />

        <div>
          <label>
            <input
              name="canPlayFri"
              style={{
                marginRight: "10px", height: "25px", width: "25px"
              }}
              type="checkbox"
              checked={canPlayFri}
              onChange={() => setCanPlayFri(!canPlayFri)}
            />
            Can play Friday evening
          </label>
        </div>
        <br />

        <div>
          <label>
            <input
              name="isGoingSatDinner"
              style={{
                marginRight: "10px", height: "25px", width: "25px"
              }}
              type="checkbox"
              checked={isGoingSatDinner}
              onChange={() => setIsGoingSatDinner(!isGoingSatDinner)}
            />
            Will attend Saturday Dinner Dance
          </label>
          <br />
          {isGoingSatDinner && (<>
            <label>
              <select
                required
                style={{ width: "100%", height: "35px" }}
                value={satDinnerGuestCount}
                onChange={event => setSatDinnerGuestCount(event.target.value)}
                onBlur={event => setSatDinnerGuestCount(event.target.value)}
              >
                <FunctionGuestSelectionOptions />
              </select>
            </label>
          </>)}
        </div>
        <br />
      </>)}

      <Agreements status="beforeSubmit" />

      <button type="submit" disabled={isInProgress || isEntryClosed}>
        Submit Entry
      </button>
    </form>)}

    {/* -- AFTER form submit -- */}
    {isSubmitted && (<>
      <EntryIntro section="EntrySubmitted" />

      <p>Tournament Name : {entry.tournamentName}</p>
      <p>
        Player Name : {entry.playerName}
        <br />
        Player Phone : {entry.playerPhone}
        <br />
        Email : {entry.playerEmail}
        <br />
        Club : {entry.playerClubName}
        <br />
        {entry.tournamentName === "QMSA_AnnualMembership_2025" && (<>
          <p>
            Note(s) :
            <br />
            Address: {playerAddress}
          </p>

          <p>
            Please pay now, if you haven't already.&nbsp;
            <a href="https://ourvoice.qmsa.asn.au/membership/">Click here and see step 2 for payment details.</a>
          </p>
        </>)}
        {entry.tournamentName === "StateTitles_AcaciaRidge_2025_0502" && (<>
          <p>
            Note(s) :
            <br />
            Player date of birth: {playerDob}
            <br />
            Event: {eventDivision}
            <br />
            {canPlayFri && (<>Can play Friday evening : Yes</>)}
            {!canPlayFri && (<>Can play Friday evening : No</>)}
            <br />
            {isGoingSatDinner && (<>
              {"Will attend Saturday Dinner Dance, with " + satDinnerGuestCount + " guest(s)"}
              <br />
            </>)}
            {!isGoingSatDinner && (<>Tournament entry only, no functions</>)}
          </p>
        </>)}
        {entry.tournamentName === "Ipswich_2025_0411" && (<>
          <p>
            Note(s) :
            <br />
            {eventDivision}
          </p>
        </>)}
      </p>

      <p>Entry Id : {entryId}</p>

      <Agreements />

      {entry.tournamentName === "StateTitles_AcaciaRidge_2025_0502" && (<>
        <p>
          Please read <a href="https://ourvoice.qmsa.asn.au/state-titles-2025">State Titles 2025</a>{" "}
          tournament information page for payment details.
          <br />
          Please pay now, if you haven't
          already.
        </p>
      </>)}

      <p>
        Please see tournament details and contact information in the{" "}
        <a href="https://ourvoice.qmsa.asn.au/calendar/">
          QMSA tournament calendar
        </a>
        .
      </p>

      <p>
        To check your entry, please <a href="/check">click here</a>.
      </p>

      <table>
        <tbody>
        <tr>
          <td>
            <a href="/">
              <button type="button">Start new entry</button>
            </a>
          </td>
          <td>
            <a href="https://ourvoice.qmsa.asn.au/">
              <button type="button">Exit</button>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </>)}
  </Layout>);
}
