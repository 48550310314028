import React from "react"

export default function EventStateTitlesSelectionOptions() {
  return (
    <>
        <option value="">-- Choose an event to enter --</option>
      <option value="W Graded">Women's Graded Individual</option>
      <option value="M Graded">Men's Graded Individual</option>
      <option value="W 35 - 39">Women's 35 - 39</option>
      <option value="W 40 - 44">Women's 40 - 44</option>
      <option value="W 45 - 49">Women's 45 - 49</option>
      <option value="W 50 - 54">Women's 50 - 54</option>
      <option value="W 55 - 59">Women's 55 - 59</option>
      <option value="W 60 - 64">Women's 60 - 64</option>
      <option value="W 65 - 69">Women's 65 - 69</option>
      <option value="W 70 - 74">Women's 70 - 74</option>
      <option value="W 75 - 79">Women's 75 - 79</option>
      <option value="W 80 - 84">Women's 80 - 84</option>
      <option value="W 85 - 89">Women's 85 - 89</option>
      <option value="W 90+">Women's 90+</option>
      <option value="M 35 - 39">Men's 35 - 39</option>
      <option value="M 40 - 44">Men's 40 - 44</option>
      <option value="M 45 - 49">Men's 45 - 49</option>
      <option value="M 50 - 54">Men's 50 - 54</option>
      <option value="M 55 - 59">Men's 55 - 59</option>
      <option value="M 60 - 64">Men's 60 - 64</option>
      <option value="M 65 - 69">Men's 65 - 69</option>
      <option value="M 70 - 74">Men's 70 - 74</option>
      <option value="M 75 - 79">Men's 75 - 79</option>
      <option value="M 80 - 84">Men's 80 - 84</option>
      <option value="M 85 - 89">Men's 85 - 89</option>
      <option value="M 90+">Men's 90+</option>
    </>
  )
}
